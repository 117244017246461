import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import BabelConfig from './babel-config.md';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>

    <h1 {...{
      "id": "parcel"
    }}>{`Parcel`}</h1>
    <p>{`You’ll need to install the `}<inlineCode parentName="p">{`@mdx-js/parcel-plugin-mdx`}</inlineCode>{` plugin to transpile MDX.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install --save-dev @mdx-js/parcel-plugin-mdx @mdx-js/react@next
`}</code></pre>
    <p>{`Parcel will then automatically be able to handle MDX files.`}</p>
    <BabelConfig />

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      