import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "babel-configuration"
    }}>{`Babel configuration`}</h1>
    <p>{`You will also need to configure `}<a parentName="p" {...{
        "href": "https://babeljs.io"
      }}>{`babel`}</a>{` to support the language features that
MDX uses.  One way you can achieve that is using the following `}<inlineCode parentName="p">{`.babelrc`}</inlineCode>{`
at your project root.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "presets": [
    "@babel/env",
    "@babel/react"
  ],
  "plugins": [
    "@babel/proposal-object-rest-spread"
  ]
}
`}</code></pre>
    <p>{`And installing the dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install --save-dev @babel/preset-env @babel/preset-react @babel/plugin-proposal-object-rest-spread
`}</code></pre>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      